// 机器人配置abt

export default [
  {  
    routeName: 'robot', 
    newPosKeys: [
      'MultiPushList',
      'UnrecongizedCard',
      'FuzzyMatchCard',
      'ConfirmationNoCard',
      'CsOrderlistCard',
      'CsOrderdetailCard',
      'CSchat',
      'ExchangeFunction',
      'BotStrategy',
      'inviteReview',
      'robotView',
      'switchOrder',
      'returnUrge'
    ],
    posKeys: [
      'Orderdetail',
      'Multiround',
      'Slq',
      'IsCanRefund',
      'giftOrderDetail',
      'IsInfoFront',
      'OrderlistQueryRelatedAccounts',
    ],
  },
  {  
    routeName: 'UserServicePhone', 
    newPosKeys: [
      'CSchat'
    ],
    posKeys: []
  }
]
